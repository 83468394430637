<script lang="ts">
  import { buttonSize, type ButtonSize } from '$lib/button-styles'
  import { track } from 'src/utils/track'
  import { createEventDispatcher } from 'svelte'
  import Spinner from '../Spinner.svelte'
  let className: string = undefined
  export { className as class }
  export let size: ButtonSize = 'md'
  export let label: string = undefined
  export let loading = false
  export let theme = 'dark'
  export let type = 'button'

  $: btnClass = buttonSize(
    className,
    size,
    `flex
      flex-row
      items-center
      justify-center
      appearance-none
      transition-all
      duration-75
      hover:opacity-90
      text-center
      rounded-full
      whitespace-nowrap
      leading-4
      focus:outline-none
      font-semibold ${
        theme == 'light'
          ? 'hover:bg-grey-md bg-grey-main text-black active:text-black disabled:cursor-not-allowed disabled:text-gray-900 active:bg-grey-lt'
          : theme == 'dark'
          ? 'hover:bg-gray-700 bg-black text-white active:text-white disabled:bg-grey-main disabled:text-gray-500 active:bg-gray-800'
          : ''
      }`,
  )

  /**
   * @param {string} eventName
   * Event Name to send to tracking service. Use the "Object Action" (Noun<>Verb) naming convention for all Track events, for example, 'Article Bookmarked'.
   */
  export let eventName: string = undefined
  export let eventDetails: Record<string, string> = undefined

  const dispatch = createEventDispatcher()
  function handleClickWithTracking(event: MouseEvent) {
    if (eventName) {
      track(eventName, eventDetails)
    }
    dispatch('click', event.detail)
  }
</script>

<button
  class={btnClass}
  {type}
  on:click|preventDefault|stopPropagation|trusted={handleClickWithTracking}
  on:mousedown
  on:mouseenter
  {...$$restProps}
>
  {#if loading}
    <span class="pr-2 -my-1">
      <Spinner {size} embedded />
    </span>
  {/if}
  <slot>{label}</slot>
</button>
